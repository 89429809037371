/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import * as messages from '../locales/en.json';
import useAnalytics from '../hooks/useAnalytics';

// components
import Tabs from '../components/common/Tabs/Tabs';

function PrivacyPage() {
  const { trackEvent } = useAnalytics();
  trackEvent('View Privacy Policy Page');

  return (
    <Layout hideNYResidentsDisclosure hideAccountOwner hideNMLS>
      <div className="landing-container">
        <SEO metadata={{ title: config.privacyTitle }} />
        <section className="privacy-policies container">
          <Tabs
            options={messages['privacy.tabs']}
            activeTab={0}
            onClick={() => trackEvent('Click Privacy Policy Tab', { Type: 'CA' })}
          />
          <div className="privacy-content">
            <section className="privacy-header">
              <h1>{messages['privacy.title']}</h1>
            </section>
            <div className="content">
              <ol>
                <li>
                  <h2>Purpose</h2>
                  <p>
                    Spring Oaks Capital, LLC and its affiliates, including Spring Oaks Capital SPV,
                    LLC (collectively “Spring Oaks”, “us,” or “we”) is committed to protecting the
                    privacy and the security of the information it receives from for the security of
                    the our assets by assets by all employees of employees of Spring Oaks,
                    (collectively, "Users") and to protect private information regarding its
                    consumers as a top priority. Pursuant to the requirements of the
                    Gramm-Leach-Bliley Act (the "GLBA") and guidelines established by the Securities
                    Exchange Commission regarding the Privacy of Consumer Financial Information
                    (Regulation P), this policy (the “Policy” or the “Regulation P “Regulation P
                    Policy”) and the related procedures contained herein are designed to comply with
                    applicable privacy laws, including the GLBA and Regulation P, and to protect
                    nonpublic personal information of consumers. In the event that new
                    privacy-related laws or regulations affecting the information practices of
                    Spring Oaks are adopted by federal or state regulators, this Policy will be
                    revised as necessary and any changes will be changes will be disseminated and
                    explained to all personnel.
                  </p>
                </li>
                <li>
                  <h2>Scope</h2>
                  <p>
                    This Policy covers the practices of Spring Oaks and applies to all nonpublic
                    personally identifiable information, including information contained in consumer
                    reports, of our current and former customers and the consumers with whom we
                    interact. All financial companies need to share personal information to run
                    their everyday business. This Policy lists the reasons financial companies can
                    share personal information, the reasons Spring Oaks chooses to share and whether
                    you can limit this sharing.
                  </p>
                </li>
                <li>
                  <h2>Policy</h2>
                  <p>
                    Spring Oaks and its employees recognize an ongoing commitment to the privacy of
                    its consumers. All employees will be expected to read, understand, and abide by
                    this policy, as well as to follow all related procedures and to uphold the
                    standards of privacy and security set forth by Spring Oaks.
                  </p>
                </li>
                <li>
                  <h2>Procedures</h2>
                  <ol>
                    <li>
                      In Regulation P, the SEC published guidelines, pursuant to section 501(b) of
                      the GLBA, that address the steps a financial institution should take in order
                      to protect consumer information. The overall security standards that must be
                      upheld are:
                      <ol>
                        <li>
                          Ensuring the security and confidentiality of consumer records and
                          information;
                        </li>
                        <li>
                          Protecting against any anticipated threats or hazards to the security or
                          integrity of consumer records and information; and
                        </li>
                        <li>
                          Protecting against unauthorized access to or use of consumer records or
                          information that could result in substantial harm or inconvenience to any
                          consumer.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Responsibility</b>
                      <ol>
                        <li>
                          Each Employee has a duty to protect the nonpublic personal information of
                          consumers collected by us.
                        </li>
                        <li>
                          Each Employee has a duty to ensure that nonpublic personal information of
                          consumers is shared only with employees and others in a way that is
                          consistent with Spring Oak’s Privacy Notice and the procedures contained
                          in this Policy.
                        </li>
                        <li>
                          Each Employee has a duty to ensure that access to nonpublic personal
                          information of consumers is limited as provided in the Privacy Notice and
                          this Policy.
                        </li>
                        <li>
                          No Employee is authorized to sell, on behalf of Spring Oaks or otherwise,
                          nonpublic information of consumers.
                        </li>
                        <li>
                          Employees with questions concerning the collection and sharing of, or
                          access to, nonpublic personal information of consumers must look to Spring
                          Oaks CCO for guidance.
                        </li>
                        <li>
                          Violations of these policies and procedures will be addressed in a manner
                          consistent with other disciplinary guidelines.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Information Practices</b>
                      <ol>
                        <li>
                          Spring Oaks limits the use, collection, and retention of consumer
                          information to what we believe is necessary or useful to conduct our
                          business or to offer quality products and services. Spring Oaks collects
                          nonpublic personal information about consumers from various sources. These
                          sources and examples of types of information collected include:
                          <ol>
                            <li>
                              Name, address, telephone number, social security number or taxpayer ID
                              number, date of birth, employment status, annual income, and net
                              worth;
                            </li>
                            <li>
                              Information about transactions with us and account custodian(s), such
                              as account balance, types of transactions, parties to the
                              transactions.
                            </li>
                            <li>
                              Information received from consumer reporting agencies, such as credit
                              reports, prior owners of the account, government agencies and other
                              companies.
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <b>Reasons we can share your personal information</b>
                            </th>
                            <th>
                              <b>Does Spring Oaks share?</b>
                            </th>
                            <th>
                              <b>Can you limit this sharing?</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              For our everyday business purposes – such as to process your
                              transactions, maintains your account(s), respond to court orders and
                              legal investigations or report to credit bureaus{' '}
                            </td>
                            <td>Yes </td>
                            <td>No </td>
                          </tr>
                          <tr>
                            <td>
                              For our marketing purposes – to offer our products and services to you{' '}
                            </td>
                            <td>No </td>
                            <td>No </td>
                          </tr>
                          <tr>
                            <td>For joint marketing with other financial companies </td>
                            <td>No </td>
                            <td>We do not share </td>
                          </tr>
                          <tr>
                            <td>
                              For our affiliates’ everyday business purposes – such as information
                              about your transactions and experiences{' '}
                            </td>
                            <td>Yes </td>
                            <td>No</td>
                          </tr>
                          <tr>
                            <td>
                              For our affiliates’ everyday business purposes – such as information
                              about your creditworthiness{' '}
                            </td>
                            <td>Yes</td>
                            <td>No</td>
                          </tr>
                          <tr>
                            <td>For nonaffiliates to market to you</td>
                            <td>No</td>
                            <td>We do not share</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        Definitions: Affiliates are companies related by common ownership or
                        control. They can be financial and non-financial companies.
                        <br />
                        Nonaffiliates are companies not related by common ownership or control. They
                        can be financial and non-financial companies.
                        <br />
                        Joint marketing is a formal agreement between nonaffiliated financial
                        companies that together market financial products or services to you. Spring
                        Oaks does not jointly market.
                      </p>
                    </li>
                    <li>
                      <b>Disclosure of Information to Nonaffiliated Third Parties</b>
                      <br />
                      Spring Oaks does not disclose nonpublic personal information to nonaffiliated
                      third parties, except under one of the GLBA privacy exceptions, as described
                      below.
                      <ol>
                        <li>
                          Types of Permitted Disclosures – The Exceptions
                          <ol>
                            <li>
                              In certain circumstances, Regulation P permits Spring Oaks to share
                              nonpublic personal information about its consumers with nonaffiliated
                              third parties without providing an opportunity for those individuals
                              to opt out. These circumstances include sharing information with a
                              nonaffiliated entity:
                              <ol>
                                <li>
                                  As necessary to effect, administer, or enforce a transaction that
                                  a client requests or authorizes;
                                </li>
                                <li>
                                  In connection with processing or servicing a collection account or
                                  a service a client authorizes; and
                                </li>
                                <li>
                                  In connection with maintaining or servicing a collection account
                                  with Spring Oaks.
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Service Providers</b>
                      <ol>
                        <li>
                          From time to time, we may have relationships with nonaffiliated third
                          parties (such as attorneys, auditors, custodians, and other consultants),
                          who, in the ordinary course of providing their services to us, may require
                          access to information containing nonpublic information. These third-party
                          service providers are necessary for us to provide our collection services.
                        </li>
                        <li>
                          When we are not comfortable that service providers (e.g., attorneys,
                          auditors, and other financial institutions) are already bound by duties of
                          confidentiality, we require assurances from those service providers that
                          they will maintain the confidentiality of nonpublic information they
                          obtain from or through us.
                        </li>
                        <li>
                          In addition, we select and retain service providers that we believe are
                          capable of maintaining appropriate safeguards for nonpublic information,
                          and we will require contractual agreements from our service providers that
                          they will implement and maintain such safeguards.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Processing and Servicing Transactions</b>
                      <ol>
                        <li>
                          We may also share information when it is necessary to effect, administer,
                          or enforce a transaction requested or authorized by our consumers.
                          <ol>
                            <li>
                              In this context, "necessary to effect, administer, or enforce a
                              transaction": includes what is required or is a usual, appropriate, or
                              acceptable method to carry out the transaction or service of which the
                              transaction is a part, and record, service, or maintain the consumer’s
                              account in the ordinary course of providing collection services.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Sharing as Permitted or Required by Law</b>
                      <ol>
                        <li>
                          Spring Oaks may disclose information to nonaffiliated third parties as
                          required or allowed by law.
                          <ol>
                            <li>
                              For example, this may include disclosures in connection with a
                              subpoena or similar legal process, a fraud investigation, an audit or
                              examination.
                            </li>
                          </ol>
                        </li>
                        <li>
                          By understanding how we share data with our clients, their agents, service
                          providers, parties related to transactions in the ordinary course of
                          business, or joint marketers, we endeavor to ensure that client data is
                          shared only within the exceptions noted above.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Privacy Notice</b>
                      <ol>
                        <li>
                          Spring Oaks will send a Privacy Notice under Regulation P on accounts it
                          owns consistent with this Policy.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2>Auditing Procedures</h2>
                  <p>
                    Spring Oaks will periodically monitor electronic systems such as e-mail and
                    Internet. Any direct, indirect, or attempted violation of this Policy, by or on
                    behalf of a User, and any actual or attempted violation by a Third Party on
                    behalf of a User, shall be considered a violation of the Policy by the User, and
                    the User shall be held directly accountable. In the event we become aware that
                    any User activity may have violated this Policy and/or exposed us to civil or
                    criminal liability, Spring Oaks reserves the right to investigate such activity;
                    monitor, collect evidence, and block access to such material; and cooperate with
                    legal authorities and Third Parties in investigating any alleged violations of
                    this Policy. We also reserve the right to implement technical mechanisms to
                    prevent policy violations including electronic monitoring of systems such as
                    e-mail and Internet. Users who violate this Policy or any other Spring
                    Oaks-published policies or standards may be subject to disciplinary action by
                    us, up to and including immediate termination from employment. In addition,
                    conduct that is unlawful under applicable laws may subject Users to civil and,
                    in some cases, criminal prosecution.
                  </p>
                </li>
                <li>
                  <h2>Links to Other Websites</h2>
                  <p>
                    Our website may contain links to enable you to visit other websites of interest
                    easily. However, once you have used these links to leave our site, you should
                    note that we do not have any control over that other website. Therefore, we
                    cannot be responsible for the protection and privacy of any information which
                    you provide while visiting such sites and such sites are not governed by this
                    privacy statement. You should exercise caution and look at the privacy statement
                    applicable to the website in question.
                  </p>
                </li>
                <li>
                  <h2>How We Protect Information</h2>
                  <p>
                    Spring Oaks has implemented physical, electronic, and procedural security
                    safeguards to protect against the unauthorized release of or access to personal
                    information. We employ internal and external system safeguards designed to
                    protect confidentiality and security of personal information. The
                    confidentiality of any communication or material transmitted to or from Spring
                    Oaks via the website or via e-mail cannot be, and is not, guaranteed. You
                    acknowledge that the technical processing and transmission of the website’s
                    content may be transferred unencrypted and involve: (a) transmissions over
                    various networks; and (b) changes to confirm and adapt to technical requirements
                    of connecting networks or devices.
                    <br /> If any questions arise about security, please contact us using the
                    information provided above.
                  </p>
                </li>
                <li>
                  <h2>Frequency of Training and Testing</h2>
                  <p>
                    All Spring Oaks personnel will receive training on our company policies
                    applicable to their position upon hire or promotion. The same personnel will
                    complete attestations and assessments on those policies upon completion of
                    training and on an annual basis.
                  </p>
                  <p>Questions or comments about our data practices can be submitted to:</p>
                  <p>
                    Spring Oaks Capital, LLC
                    <br />
                    P.O. Box 1216
                    <br />
                    Chesapeake, VA 23327-1216
                  </p>
                  <p>Or by calling us, toll-free, at 866-281-3065.</p>
                </li>
              </ol>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivacyPage;
